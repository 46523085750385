import { ToggleButton } from 'vue-js-toggle-button'
import vueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

import globalHelper from '@/mixins/global'

export default {
  name: 'notifications-settings',
  components: {
    ToggleButton,
    vueSlider
  },
  props: ['notifications_email', 'emails_frequency', 'notifications_push', 'push_denied'],
  mixins: [globalHelper],

  computed: {
    direction () {
      return document.querySelector('html').getAttribute('dir') || 'ltr'
    }
  },
  methods: {
    updateEmailNotifications ({ srcEvent, value }) {
      srcEvent.preventDefault()
      srcEvent.stopPropagation()

      this.$emit('input', { property: 'notifications_email', value })
    },
    updateEmailNotificationsFrequency (value) {
      this.$emit('input', { property: 'emails_frequency', value })
    },
    updatePushNotifications ({ srcEvent, value }) {
      srcEvent.preventDefault()
      srcEvent.stopPropagation()

      if (!this.$messaging) {
        this.$store.dispatch('device_set', false)
        return
      }

      Notification.requestPermission()
        .then(
          (response) => {
            if (response === 'denied') {
              this.$store.dispatch('device_set', false)
            } else {
              this.$store.dispatch('device_get')
                .then(
                  (response) => {
                    this.$emit('input', { property: 'notifications_push', value: value ? response : value })
                  },
                  () => {
                    this.$emit('input', { property: 'notifications_push', value: false })
                  })
            }
          },
          () => {
            // do not catch errors from the success handler. they are handled there
            this.$store.dispatch('device_set', false)
          })
    }
  }
}
