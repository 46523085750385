import vueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import globalHelper from '@/mixins/global'

export default {
  name: 'filter-attribute-slider',
  components: {
    vueSlider
  },
  props: ['attribute', 'value'],
  mixins: [globalHelper],

  data () {
    const upstreamValue = this.value || [null, null]

    return {
      defaultValue: [
        upstreamValue[0] || this.attribute.min || 0,
        upstreamValue[1] || this.attribute.max || 1
      ]
    }
  },

  mounted () {
    this.handleInput(this.defaultValue)
  },

  computed: {
    direction () {
      return document.querySelector('html').getAttribute('dir') || 'ltr'
    }
  },

  methods: {
    format (v) {
      return this.attribute.key && this.$te(`player.levels.${this.attribute.key}[${v}]`) ? this.$t(`player.levels.${this.attribute.key}[${v}]`) + ` (${v})` : v
    },

    handleInput (v) {
      this.$emit('input', v)
    }
  }
}
