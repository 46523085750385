import vueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import globalHelper from '@/mixins/global'

export default {
  name: 'filter-attribute-age',
  components: {
    vueSlider
  },
  props: ['attribute', 'value'],
  mixins: [globalHelper],

  data () {
    const ymin = this.value ? Math.floor(this.value[0]) : null
    const ymax = this.value ? Math.floor(this.value[1]) : null

    const dmin = this.value ? Math.floor(this.value[0] * 1000 - ymin * 1000) : null
    const dmax = this.value ? Math.floor(this.value[1] * 1000 - ymax * 1000) : null

    return {
      years: [
        ymin || this.attribute.min || 17,
        ymax || this.attribute.max || 40
      ],
      dayMin: dmin || 0,
      dayMax: dmax || 111
    }
  },

  mounted () {
    const ymin = this.years[0] + this.dayMin / 1000
    const ymax = this.years[1] + this.dayMax / 1000

    this.$emit('input', [ymin, ymax])
  },
  watch: {
    years: function (v) {
      const ymin = v[0] + this.dayMin / 1000
      const ymax = v[1] + this.dayMax / 1000

      this.$emit('input', [ymin, ymax])
    },
    dayMin: function (v) {
      v = v || 0
      const ymin = this.years[0] + v / 1000
      const ymax = this.years[1] + this.dayMax / 1000

      this.$emit('input', [ymin, ymax])
    },
    dayMax: function (v) {
      v = v || 0
      const ymin = this.years[0] + this.dayMin / 1000
      const ymax = this.years[1] + v / 1000

      this.$emit('input', [ymin, ymax])
    }
  },

  computed: {
    direction () {
      return document.querySelector('html').getAttribute('dir') || 'ltr'
    }
  },

  methods: {
    formatYears (v) {
      return this.$te('general.years') ? this.$tc('general.years', v) : v
    },
    formatDays (v) {
      return this.$te('general.days') ? this.$tc('general.days', v) : v
    },

    errorMinDay (type, msg) {
      this.dayMin = 0
    },
    errorMaxDay (type, msg) {
      this.dayMax = 111
    },
    handleMinDayInput (v) {
      if (!v) {
        this.dayMin = 0
      }
    },
    handleMaxDayInput (v) {
      if (!v && v !== 0) {
        this.dayMax = 111
      }
    }
  }
}
