import globalHelper from '@/mixins/global'

export default {
  name: 'filter-attribute-choices',
  components: {
  },
  props: ['attribute', 'value'],
  mixins: [globalHelper],

  data () {
    return {
      defaultValue: this.value || []
    }
  },

  created () {
  },

  computed: {
  },
  watch: {
    defaultValue: function (newVal, oldVal) {
      if (!newVal.length) {
        this.$emit('input', null)
      } else {
        this.$emit('input', newVal)
      }
    }
  }
}
