import globalHelper from '@/mixins/global'

export default {
  name: 'filter-attribute-numeric',
  components: {
  },
  props: ['attribute', 'value'],
  mixins: [globalHelper],

  data () {
    const upstreamValue = this.value || [null, null]

    return {
      minValue: upstreamValue[0] || this.attribute.min || 0,
      maxValue: upstreamValue[1] || this.attribute.max || 1
    }
  },

  created () {
  },
  mounted () {
    this.$emit('input', [this.minValue, this.maxValue])
  },

  computed: {
  },

  methods: {
    handleMin (event) {
      this.minValue = parseInt(event.target.value) || 0
      if (this.minValue < 0 && this.attribute.type === 'integer') {
        this.minValue = 0
      }
      if (this.maxValue < this.minValue) {
        this.maxValue = parseInt(event.target.value)
      }
      this.$emit('input', [this.minValue, this.maxValue]) // sort((a, b) => a - b)
    },
    handleMax (event) {
      this.maxValue = parseInt(event.target.value) || 0
      if (this.maxValue < 0 && this.attribute.type === 'integer') {
        this.maxValue = 0
      }
      if (this.maxValue < this.minValue) {
        this.minValue = parseInt(event.target.value)
      }
      this.$emit('input', [this.minValue, this.maxValue]) // sort((a, b) => a - b)
    }
  }
}
