import FilterAttributeAge from '@/components/FilterAttribute/Age/main'
import FilterAttributeChoice from '@/components/FilterAttribute/Choice/main'
import FilterAttributeChoices from '@/components/FilterAttribute/Choices/main'
import FilterAttributeCountry from '@/components/FilterAttribute/Country/main'
import FilterAttributeNumeric from '@/components/FilterAttribute/Numeric/main'
import FilterAttributeSlider from '@/components/FilterAttribute/Slider/main'
import RollUp from '@/components/RollUp/main'
import globalHelper from '@/mixins/global'

export default {
  name: 'filter-attribute',
  components: {
    FilterAttributeAge,
    FilterAttributeChoice,
    FilterAttributeChoices,
    FilterAttributeCountry,
    FilterAttributeNumeric,
    FilterAttributeSlider,
    RollUp
  },
  props: ['attribute'],
  mixins: [globalHelper],

  data () {
    return {
      downstreamValue: null,
      show: false,
      currentComponent: null
    }
  },

  created () {
    switch (this.attribute.type) {
      case 'age': this.currentComponent = 'filter-attribute-age'
        break
      case 'range': this.currentComponent = 'filter-attribute-slider'
        break
      case 'integer':
      case 'numeric':
      case 'money': this.currentComponent = 'filter-attribute-numeric'
        break
      case 'country': this.currentComponent = 'filter-attribute-country'
        break
      case 'multichoice': this.currentComponent = 'filter-attribute-choices'
        break
      case 'choice': this.currentComponent = 'filter-attribute-choice'
        break
    }
  },

  computed: {
    currentValue () {
      if (this.$store.state.filter && this.$store.state.filter[this.attribute.name] !== undefined) {
        if (this.$store.state.filter[this.attribute.name] instanceof Array && !this.$store.state.filter[this.attribute.name].length) {
          return null
        }

        return this.$store.state.filter[this.attribute.name]
      }
      return null
    },
    className () {
      return this.attribute.name || ''
    }
  },

  methods: {
    translate (attrName, value, self) {
      if (value === null || value === undefined) {
        return value
      }

      if (!self) {
        self = this
      }

      if (value instanceof Array && value.length) {
        const composed = value.map(function (subvalue) {
          return self.translate(attrName, subvalue, self)
        })
        return composed.join(' - ')
      }

      switch (attrName) {
        case 'form':
        case 'experience':
        case 'leadership':
        case 'stamina_skill':
        case 'keeper_skill':
        case 'playmaker_skill':
        case 'scorer_skill':
        case 'passing_skill':
        case 'winger_skill':
        case 'defender_skill':
        case 'set_pieces_skill':
          return self.$te(`player.levels.skill[${value}]`) ? self.$t(`player.levels.skill[${value}]`) + ` (${value})` : value
        case 'agreeability':
          return self.$te(`player.levels.agreeability[${value}]`) ? self.$t(`player.levels.agreeability[${value}]`) + ` (${value})` : value
        case 'aggressiveness':
          return self.$te(`player.levels.aggressiveness[${value}]`) ? self.$t(`player.levels.aggressiveness[${value}]`) + ` (${value})` : value
        case 'honesty':
          return self.$te(`player.levels.honesty[${value}]`) ? self.$t(`player.levels.honesty[${value}]`) + ` (${value})` : value
        case 'specialty':
        case 'specialties':
          return self.$te(`player.levels.specialty[${value}]`) ? self.$t(`player.levels.specialty[${value}]`) : value
        case 'native_country_id':
          return self.$te(`countries[${value}]`) ? self.$t(`countries[${value}]`) : value
        case 'age':
          // eslint-disable-next-line
          const y = Math.floor(value)
          // eslint-disable-next-line
          const d = Math.floor(value * 1000 - y * 1000)
          return (self.$te('general.years') ? self.$tc('general.years', y) : `${y}y`) + ', ' + (self.$te('general.days') ? self.$tc('general.days', d) : `${d}d`)
        case 'htms':
        case 'htms28':
        case 'tsi':
          return self.$n(value)
        case 'salary':
        case 'price':
          return self.country.currency_code
            ? self.$n(value, { style: 'currency', currency: self.country.currency_code, minimumFractionDigits: 0 })
            : self.$n(value)
        case 'NT_player':
          return self.$te(`player.levels.national_team[${value}]`) ? self.$t(`player.levels.national_team[${value}]`) : value
        default: return value
      }
    },

    close () {
      this.show = false
    },
    open () {
      this.show = true
    },
    set () {
      // set the value on the object
      this.$store.commit('updateFilter', { property: this.attribute.name, value: this.downstreamValue })
      this.close()
    },
    cancel () {
      // clear the value from the object
      const empty = this.attribute.type !== 'text' && this.attribute.type !== 'country' && this.attribute.type !== 'choice' ? [] : null
      this.$store.commit('updateFilter', { property: this.attribute.name, value: empty })
      this.close()
    },
    handleInput (val) {
      this.downstreamValue = val
    }
  }
}
