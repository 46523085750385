import { ValidationObserver, ValidationProvider } from 'vee-validate'
import findIndex from 'lodash.findindex'
import get from 'lodash.get'
import FilterAttribute from '@/components/FilterAttribute/main'
import NotificationsSettings from '@/components/NotificationsSettings/main'
import filter from '@/constants/filter'
import globalHelper from '@/mixins/global'

export default {
  name: 'filters-addedit',
  components: {
    FilterAttribute,
    NotificationsSettings,
    ValidationObserver,
    ValidationProvider
  },
  mixins: [globalHelper],

  created () {
    // load the filter or initialize a new one
    const id = this.$route.params.id || null
    this.$store.dispatch('filter_get', id).then(
      (response) => {
      },
      (response) => {
        this.handleErrors(response)
      }
    )
  },
  mounted () {
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'setLanguage':
          this.$refs.observer && this.$refs.observer.ctx && this.$refs.observer.ctx.invalid && this.$refs.observer.validate()
          break
      }
    })
  },

  computed: {
    attributes () {
      return filter.SCHEMA.filter(attribute => attribute.group !== undefined && attribute.group === 'filter')
    },
    name: {
      get () {
        return get(this.$store.state, 'filter.name', null)
      },
      set (value) {
        this.$store.commit('updateFilter', { property: 'name', value })
      }
    },
    notifications_email () {
      return get(this.$store.state, 'filter.settings.notifications_email', null)
    },
    emails_frequency () {
      return get(this.$store.state, 'filter.settings.emails_frequency', null)
    },
    notifications_push () {
      if (this.is_push_blocked || !this.$store.state.device) {
        return false
      }

      return get(this.$store.state, 'filter.settings.notifications_push', false) &&
        this.$store.state.user.devices && this.$store.state.user.devices.length &&
        findIndex(this.$store.state.user.devices, ['token', this.$store.state.device]) >= 0
    },
    canSave () {
      return get(this.$store.state, 'filter.dirty', false)
    }
  },

  methods: {
    handleNotifications (v) {
      if (v.property === 'notifications_push') {
        if (v.value) {
          // register user device
          this.$store.dispatch('user_device_register', v.value)
            .catch((response) => {
              this.printErrors(response, this.$refs.observer, 'notifications')
            })
        }
        // cast to boolean
        v.value = !!v.value
      }

      v.property = `settings.${v.property}`

      this.$store.commit('updateFilter', v)
    },
    async save (event) {
      event.preventDefault()
      event.stopPropagation()

      const isValid = await this.$refs.observer.validate()

      if (!isValid) {
        return
      }

      const obj = this.$helpers.transformFilterToDB(this.$store.state.filter)
      this.$store.dispatch('filter_update', obj).then(
        (response) => {
          this.$router.push({ name: 'Home' })
        },
        (response) => {
          this.handleErrors(response)
        }
      )
    }
  }
}
