import find from 'lodash.find'
import shuffle from 'lodash.shuffle'
import Dropdown from 'vue-simple-search-dropdown'
import globalHelper from '@/mixins/global'

export default {
  name: 'filter-attribute-country',
  components: {
    Dropdown
  },
  props: ['attribute', 'value'],
  mixins: [globalHelper],

  data () {
    return {
      defaultValue: this.getCountryById(this.value)
    }
  },

  created () {
  },

  computed: {
    items () {
      return Array.isArray(this.$store.state.countries) ? shuffle(this.$store.state.countries.map((item) => {
        item.name = item.id && this.$te(`countries[${item.id}]`) ? this.$t(`countries[${item.id}]`) : item.name
        return item
      })) : this.$store.state.countries
    }
  },

  methods: {
    handleInput (v) {
      if (v && v.id) {
        this.$emit('input', v.id)
      } else {
        this.$emit('input', null)
      }
    },
    getCountryById (id) {
      return find(this.$store.state.countries, ['id', id])
    }
  }
}
