import globalHelper from '@/mixins/global'

export default {
  name: 'filter-attribute-choice',
  components: {
  },
  props: ['attribute', 'value'],
  mixins: [globalHelper],

  data () {
    const upstreamValue = this.value || this.value === 0 ? this.value : null

    return {
      defaultValue: upstreamValue
    }
  },

  created () {
  },

  computed: {
  },

  methods: {
    handleInput (v) {
      this.$emit('input', v)
    }
  }
}
